import { INIT_SESSION, GET_TOKEN, GET_POINTS, REDEEM_POINTS, SET_POINTS, GET_CURRENCY, SET_CURRENCY, GET_TRANSACTIONS } from '../types/PointsTypes';
//constants
import { updateObject } from '../../constants/Helpers';

const initialState = {
	userId: '',
    jwt: '',
    points: {},
    redeemedPoints: '',
    currency: {},
    transactions: []
};

const PointsReducer = (state = initialState, action) => {
	switch (action.type) {
        case INIT_SESSION: {
            return updateObject(state, { userId: action.userId });
        }
        case GET_TOKEN: {
            return updateObject(state, { jwt: action.jwt });
        }
        case GET_CURRENCY: {
            return updateObject(state, { currency: action.currency });
        }
        case SET_CURRENCY: {
            return updateObject(state, { currency: action.currency });
        }
        case GET_POINTS: {
            return updateObject(state, { points: action.points });
        }
        case SET_POINTS: {
            return updateObject(state, { points: action.points });
        }
        case REDEEM_POINTS: {
            return updateObject(state, { redeemedPoints: action.redeemedPoints });
        }
        case GET_TRANSACTIONS: {
            return updateObject(state, { transactions: action.transactions });
        }
		default:
			return state;
	}
};

export default PointsReducer;