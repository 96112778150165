export const initSessionEndPoint = () => '/loyalty/session/init';

export const getTokenEndPoint = (token) => `/loyalty/session/${token}`;

export const getPointsEndPoint = () => `/loyalty/points/banner`;

export const redeemPointsEndPoint = () => `/loyalty/redemption`;

export const getCurrencyEndPoint = (currency) => `/loyalty/currencies/${currency}`;

export const getStatementsEndPoint = () => `/loyalty/points/statements`;