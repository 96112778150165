import { SET_LANG_DATA } from '../types/LangTypes';
//Translations
import {en} from '../../translation/en';
import {ar} from '../../translation/ar';
//constants
import { updateObject } from '../../constants/Helpers';

const initialState = {
	lang: "en",
    langJson: en
};

const LangReducer = (state = initialState, action) => {
	switch (action.type) {
        case SET_LANG_DATA: {
            const { lang } = action;
            return updateObject(state, { lang, langJson: lang === 'en' ? en : ar });
        }
        default:
            return state; 
	}
};

export default LangReducer;