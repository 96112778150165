//URLs
import { getHomePageUrl, getEarnPointsPageUrl, getVIPPageUrl } from "./AppUrls";
//Components
import Home from '../pages/Home';
import EarnPoints from '../pages/EarnPoints';
import Vip from '../pages/Vip';

export const routes = [
    {
		path: () => getEarnPointsPageUrl(),
		Component: EarnPoints,
	},
	{
		path: () => getVIPPageUrl(),
		Component: Vip,
	},
	{
		path: () => getHomePageUrl(),
		Component: Home,
	},
];