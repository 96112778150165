import PointsTransactions from 'components/modals/PointsTransactions';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTransactions } from '../../store/selectors/PointsSelector';
//selectors
import { getLangJson } from '../../store/selectors/LangSelector';
import { getStatements } from 'store/actions/PointsActions';

function PointsHistory() {
  const [isPointsTransactionsModalOpen, setIsPointsTransactionsModalOpen] = useState(false);
  const t = useSelector(state => getLangJson({ state }));
  const transactions = useSelector(state => getTransactions({ state }));
  const dispatch = useDispatch();

  const closeModal = () => setIsPointsTransactionsModalOpen(false);

  const openModal = () => {
    dispatch(getStatements());

    setIsPointsTransactionsModalOpen(true);
  };

  return (
    <div className='pointshistory-div'>
      <div className='pointshistory-content'>
        <div className='d-flex align-items-baseline'>
          <h2 className='pointshistory-title sz-16'>{t.pointsHistory.title}</h2>
          <p onClick={openModal} className='pointshistory-link medium-txt sz-16'>
            {t.pointsHistory.viewDetails}
          </p>
        </div>
        <p className='pointshistory-txt medium-txt sz-12'>{t.pointsHistory.desc}</p>
      </div>

      {isPointsTransactionsModalOpen ? (
        <PointsTransactions toggleFunc={closeModal} transactions={transactions} />
      ) : null}
    </div>
  );
}

export default PointsHistory;
