import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
//selectors
import { getLangJson } from '../../store/selectors/LangSelector';
// SVG
import { ReactComponent as Back } from '../../assets/images/back.svg';

function PointsTransactions({ toggleFunc, transactions }) {
  const t = useSelector(state => getLangJson({ state }));
  const rewardTypes = {
    Purchase: 0,
    Redeem: 1,
    Open: 2,
    Signup: 3,
    ExpiredBalance: 4,
    PromotionalCampaign: 5,
  };
  // lang = useSelector(state => getLang({ state }));
  const isCredit = type => {
    switch (type) {
      case rewardTypes.Purchase:
        return true;

      case rewardTypes.Signup:
        return true;

      case rewardTypes.ExpiredBalance:
        return false;

      case rewardTypes.Redeem:
        return false;

      case rewardTypes.Open:
        return true;

      default:
        return false;
    }
  };
  return (
    <div className='pointstransactions-div'>
      <div className='pointstransactions-overlay' onClick={toggleFunc}></div>
      <div className='pointstransactions-content white-bg'>
        <div className='pointstransactions-header'>
          <div className='pointstransactions-icon-holder'>
            <Back className='icon' onClick={toggleFunc} />
          </div>
          <h3 className='pointstransactions-header-title'>{t.pointsHistory.title}</h3>
        </div>
        {transactions && transactions.length > 0 ? (
          transactions.map(transaction => (
            <div key={transaction.id} className='pointstransactions-transaction'>
              <p className='pointstransactions-title sz-20 medium-txt'>
                {t.pointsHistory[transaction.rewardTypeString]}{' '}
                {transaction.referenceId ? (
                  <span className='sz-16'>
                    ({t.pointsHistory.orderId + '#' + transaction.referenceId})
                  </span>
                ) : null}{' '}
                {transaction.rewardType === rewardTypes.ExpiredBalance ? (
                  <span>({t.pointsHistory.expiredBalancePeriod})</span>
                ) : null}
              </p>
              <p className='pointstransactions-amount sz-20 medium-txt'>
                <span className={isCredit(transaction.rewardType) ? 'text-success' : 'text-danger'}>
                  {isCredit(transaction.rewardType) ? '+' : '-'}
                </span>{' '}
                {(isCredit(transaction.rewardType) ? transaction.credit : transaction.debit) +
                  ' ' +
                  t.pointsHistory.pts}
              </p>
              <p className='pointstransactions-txt sz-14 light-txt'>{transaction.createdAt}</p>
            </div>
          ))
        ) : (
          <div className='transaction-empty'>
            <h3>{t.pointsHistory.transactionEmptyTitle}</h3>
            <p>{t.pointsHistory.transactionEmptySub}</p>
          </div>
        )}
      </div>
    </div>
  );
}

PointsTransactions.propTypes = {
  toggleFunc: PropTypes.func.isRequired,
  transactions: PropTypes.array.isRequired,
};

export default PointsTransactions;
