import React from "react";
import { useSelector } from 'react-redux';

import { useHistory, useLocation } from "react-router-dom";
import { getLangJson } from "../../store/selectors/LangSelector";
//svg
import { ReactComponent as Back } from '../../assets/images/back.svg';
import PropTypes from 'prop-types';

const NavTabs = ({ handleBack }) => {

  const history = useHistory(),
    location = useLocation(),
    t = useSelector((state) => getLangJson({ state }));

  return (
    <div className="navtabs-wrapper">
      <Back className="back-icon blue-back" onClick={handleBack} />
      <div className="typetabs-wrapper">
        <div className={`typetab medium-txt sz-16 ${location.pathname === "/session" ? "active-typetab" : ""}`} onClick={() => history.push("/session")}>
          {t.flowardPoints}
        </div>
        <div className={`typetab medium-txt sz-16 ${location.pathname === "/vip" ? "active-typetab" : ""}`} onClick={() => history.push("/vip")}>
          {t.flowardVip}
        </div>
      </div>
    </div>
  );
};
NavTabs.propTypes = {
  handleBack: PropTypes.func.isRequired

};

export default NavTabs;