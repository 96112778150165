export const ar = {
  flowardPoints: 'نقاط فلاورد',
  flowardVip: 'فلاورد VIP',
  home: {
    desc: 'اجمع نقاط فلاورد من كل طلب تقوم بطلبه. كلما زادت الهدايا التي تقوم بإهدائها، كلما زادت النقاط التي تربحها.',
    redeemBtn: 'استرداد النقاط',
    noPointRedeemBtn: 'لا يوجد نقاط متاحة للاسترداد',
    missingPointsRedeemBtn: amount => `${amount} نقاط متبقية لتقوم بالاسترداد`,
  },
  balance: {
    title: 'الرصيد المتوفر',
    points: 'نقطة',
    desc: 'سيتم إضافة الرصيد تلقائياً إلى محفظة فلاورد الخاصة بك عند استرداد نقاطك.',
  },
  earnpoints: {
    title: 'كيف يمكنك ربح المزيد من النقاط؟',
    link: 'اعرف أكثر',
    points: {
      title: (amount, currency) => `اكسب ${amount} نقطة مقابل كل ${currency} تنفقه`,
      desc: (amount, currency) =>
        `مقابل كل ${amount} نقطة، ستحصل على رصيد فلاورد بقيمة 1 ${currency}`,
    },
    delivery: {
      title: 'يمكنك استخدام نقاطك للحصول على رصيد فلاورد في أي بلد',
      desc: amount => `مقابل كل ${amount} نقطة، ستظهر النقاط في حسابك بعد تسليم الطلب`,
    },
    vat: {
      title: 'يتم احتساب النقاط على أساس إنفاقك مطروحاً منه أي خصومات وضريبة القيمة المضافة',
    },
    note: {
      title: 'يرجى العلم أن قيمة النقاط قد تتغير في المستقبل',
      desc: 'كل النقاط التي تربحها بداية من 1 يونيو2022 ستنهي صلاحيتها بعد 60 يوم.',
      note: 'إذا قمت بإلغاء الطلب فسيتم إلغاء النقاط المرتبطة به',
    },
  },
  pointsHistory: {
    title: 'سجل نقاطك',
    viewDetails: 'عرض التفاصيل',
    desc: 'راجع تاريخ نقاطك',
    expiredBalancePeriod: 'عام واحد',
    pts: 'نقاط',
    orderId: 'طلب',
    Purchase: 'شراء',
    Redeem: 'نقاط مُستبدلة',
    ExpiredBalance: 'نقاط منتهية الصلاحية',
    Signup: 'نقاط ترحيبية',
    transactionEmptyTitle: `لم تربح أي نقاط حتى الآن!`,
    transactionEmptySub: `قم بعمل طلب جديد، وسيتم اضافة النقاط إلى حسابك.`,
  },
  congrats: {
    title: 'تهانينا!',
    desc: (points, currency) =>
      `تمت إضافة رصيد بقيمة ${points} ${currency} إلى محفظة فلاورد الخاصة بك `,
    note: 'يمكنك استخدام الرصيد بالطلب التالي',
    errorPoints: 'عذراً! لا تملك نقاط كافية.',
  },
  expiryPoints: {
    title: 'انتهاء صلاحية النقاط',
    points: points => `لديك ${points}`,
    pointsTime: expiringDays => `من النقاظ التي ستنتهي بعد${expiringDays} يوم`,
    expiryTime: `كل النقاط التي تربحها بداية من 1 يونيو 2022 ستنتهي صلاحيتها بعد 60 يوم، وذلك طبقاً للتحديثات على البرنامج.`,
    terms: 'يرجى الاطلاع على الشروط والأحكام ',
  },
  vip: {
    yourVip: `أنت الآن عضو VIP`,
    desc: 'احصل على عروض حصرية مع كل طلب تقوم بطلبه عبر التطبيق',
    pointsTitle: 'إجمالي النقاط التي جمعتها حتى الآن',
    reachVip: points => `اجمع ${points} نقطة إضافية لتصبح `,
    calculatingPoints: `نقوم بحساب النقاط التي ربحتها خلال آخر ستة أشهر`,
    vipReached: (points, date) => `اجمع ${points} نقطة اضافية قبل ${date} لتظل Floward VIP `,
    remainVip: 'لقد ربحت النقاط المطلوبة لتحافظ على حالة VIP',
    benefits: {
      title: 'ما هي مميزات فلاورد VIP؟',
      desc: 'استمتع بتجربة أسهل لإرسال الهدايا، ستحصل على عروض خاصة وخصومات حصرية، وأيضاً أسعار أقل.\nمزيد من المزايا قريباَ',
      whyPointsChangeTitle: 'لماذا يتغير رصيد نقاطي؟',
      whyPointsChangeDesc:
        'نقوم بحساب النقاط التي ربحتها في آخر 6 أشهر ويتم تحديث الرصيد طبقاً لذلك. يتغير الرصيد في حالة اضافة نقاط جديدة، أو ازالتها من رصيدك في حالة مرور أكثر من ستة أشهر.',
      vipStatus:
        'حالة الـVIP ستظل صالحة لمدة ستة أشهر، سيتم تجديدها تلقائياً إذا قمت بإحراز النقاط المطلوبة في خلال هذه المدة.',
      firstBenefit: 'توصيل مجاني',
      secondBenefit: 'أولوية في التوصيل',
      commingSoon: 'مزايا أخرى قريباً',
      earnInfo: points => `أحرز ${points} نقطة خلال 6 أشهر لتصبح عضو Floward VIP`,
    },
    yourStatus: 'حالة حسابك',
    yourTotalPoints: 'إجمالي النقاط التي جمعتها حتى الآن',
    pointsToUnlock: 'نقاط لتصل الى مستوى VIP',
  },
};
