export const INIT_SESSION = '[POINTS] INIT_SESSION';

export const GET_TOKEN = '[POINTS] GET_TOKEN';

export const GET_POINTS = '[POINTS] GET_POINTS';

export const SET_POINTS = '[POINTS] SET_POINTS';

export const REDEEM_POINTS = '[POINTS] REDEEM_POINTS';

export const GET_CURRENCY = '[POINTS] GET_CURRENCY';

export const SET_CURRENCY = '[POINTS] SET_CURRENCY';

export const GET_TRANSACTIONS = '[POINTS] GET_TRANSACTIONS';
