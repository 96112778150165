import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
//selectors
import { getLangJson, getLang } from '../../store/selectors/LangSelector';
//svg
import { ReactComponent as VipPointsIcon } from '../../assets/images/vip-points.svg';
import { ReactComponent as VipPointsIconAr } from '../../assets/images/vip-points-ar.svg';

function VipPoints({ points, isVip }) {
  const t = useSelector(state => getLangJson({ state })),
    lang = useSelector(state => getLang({ state })),
    cdate = new Date(points?.pointsDeadlineDate).toLocaleString('en-US', { dateStyle: 'medium' });

  return (
    <div className='balance-div white-bg vip-points-div'>
      {isVip ? (
        <div className='balance-content'>
          <p className='balance-points sz-20 bold-txt'>{t.vip.yourVip}</p>

          <progress className='progress-bar' value={points.percentage ?? 0} max='100'></progress>

          {points.showMaintainability ? (
            <p className='balance-points sz-12'>
              {t.vip.vipReached(points.remainingPoints, cdate)}
            </p>
          ) : (
            <p className='balance-points sz-12'>{t.vip.remainVip}</p>
          )}
        </div>
      ) : (
        <div className='balance-content'>
          {lang === 'ar' ? <VipPointsIconAr className='img' /> : <VipPointsIcon className='img' />}
          <p className='balance-points sz-20 bold-txt'>{t.vip.reachVip(points.remainingPoints)}</p>
          <progress className='progress-bar' value={points.percentage ?? 0} max='100'></progress>
          <p className='balance-points sz-12'>{t.vip.calculatingPoints}</p>
        </div>
      )}
    </div>
  );
}

VipPoints.propTypes = {
  points: PropTypes.object.isRequired,
  isVip: PropTypes.bool.isRequired,
};

export default VipPoints;
