import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from './routes/RoutesConfig';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import NavTabs from './components/common/NavTabs';
//constants
import { decodeToken, getQueryParams } from './constants/Helpers';
//selectors
import { getLang } from './store/selectors/LangSelector';
import { getUserId, getJWTData } from './store/selectors/PointsSelector';
//actions
import { setToken, getJWT, getPoints, getCurrency } from './store/actions/PointsActions';
//svg
import { ReactComponent as Back } from './assets/images/back.svg';

function App() {
  const lang = useSelector(state => getLang({ state })),
    [addBackBtn, setAddBackBtn] = useState(false),
    history = useHistory(),
    location = useLocation(),
    jwt = useSelector(state => getJWTData({ state })),
    queryParams = getQueryParams(location.search),
    userId = useSelector(state => getUserId({ state })),
    dispatch = useDispatch();

  window.addEventListener('message', event => {
    if (event.data && event.data.key) {
      let key = event.data.key.toLowerCase();
      switch (key) {
        case 'addbackbtn':
          setAddBackBtn(true);
          break;
        default:
          setAddBackBtn(false);
          break;
      }
    }
  });

  const handleBack = () => {
    if (location.pathname === '/session') {
      const data = {
        key: 'journeyCompleted',
      };
      window.parent.postMessage(data, '*');
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    if (queryParams.token) {
      dispatch(setToken(queryParams.token));
    }
  }, []);

  useEffect(() => {
    if (userId !== '') dispatch(getJWT(userId));
  }, [userId]);

  useEffect(() => {
    if (jwt !== '') {
      dispatch(getCurrency(decodeToken(jwt).Currency));
      dispatch(getPoints(decodeToken(jwt).Currency));
    }
  }, [jwt]);

  return (
    <div className={lang === 'ar' ? 'App ar-app' : 'App'} data-testid='ApptestId'>
      {addBackBtn ? (
        location.pathname === '/session' || location.pathname === '/vip' ? (
          <NavTabs handleBack={handleBack} />
        ) : (
          <Back className='back-icon' onClick={handleBack} />
        )
      ) : null}
      <Switch>
        {routes.map((el, i) => (
          <Route path={el.path()} render={propRouter => <el.Component {...propRouter} />} key={i} />
        ))}
      </Switch>
      <ToastContainer />
    </div>
  );
}
export default App;