import React, { useState, useEffect } from 'react';
import CustomButton from '../components/common/CustomButton';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import { ReactComponent as ArabicLogo } from '../assets/images/logo-arabic.svg';

import Balance from '../components/cards/Balance';
import EarnPoints from '../components/cards/EarnPoints';
import PointsHistory from 'components/cards/PointsHistory';
import Congrats from '../components/modals/Congrats';
import Loader from '../components/common/Loader';
import { useSelector, useDispatch } from 'react-redux';
//selectors
import { getLangJson, getLang } from '../store/selectors/LangSelector';
import {
  getJWTData,
  getPointsData,
  redeemedPointsData,
  getCurrencyData,
} from '../store/selectors/PointsSelector';
import { getLoading, getRedeeming } from '../store/selectors/AppSelector';
//actions
import { redeemPoints, setPoints, getPoints } from '../store/actions/PointsActions';
//constants
import { decodeToken } from '../constants/Helpers';
//toast
import { toast } from 'react-toastify';

function Home() {
  const [isCongratsModelOpen, setIsCongratsModalOpen] = useState(false),
    isRedeeming = useSelector(state => getRedeeming({ state })),
    t = useSelector(state => getLangJson({ state })),
    jwt = useSelector(state => getJWTData({ state })),
    points = useSelector(state => getPointsData({ state })),
    redeemed = useSelector(state => redeemedPointsData({ state })),
    loading = useSelector(state => getLoading({ state })),
    currency = useSelector(state => getCurrencyData({ state })),
    lang = useSelector(state => getLang({ state })),
    dispatch = useDispatch();

  const redeemFunc = () => {
    if (points.currentPoints === 0) {
      toast.error(t.congrats.errorPoints);
    } else {
      dispatch(redeemPoints({ points: points.currentPoints, currency: decodeToken(jwt).Currency }));
    }
  };
  const closeModal = () => {
    dispatch(setPoints({ points: 0, actual: 0 }));
    setIsCongratsModalOpen(false);
    dispatch(getPoints(decodeToken(jwt).Currency));
  };

  useEffect(() => {
    if (isRedeeming === false && redeemed !== '') setIsCongratsModalOpen(true);
  }, [isRedeeming, redeemed]);

  return (
    <div className='main-container'>
      {loading ? (
        <Loader height={'100%'} />
      ) : (
        <>
          <div className='blue-section'>
            {lang === 'ar' ? (
              <ArabicLogo className='home-title' />
            ) : (
              <Logo className='home-title' />
            )}
            <p className='home-txt sz-16'>{t.home.desc}</p>
          </div>
          <Balance points={points} currency={currency} />
          <div className='white-section'>
            <div className='white-section-content'>
              <CustomButton
                disabled={!points.isRedeemable}
                text={
                  points.isRedeemable
                    ? points.currentPoints < currency.points
                      ? t.home.missingPointsRedeemBtn(currency.points - points.currentPoints)
                      : t.home.redeemBtn
                    : t.home.noPointRedeemBtn
                }
                clickFunc={redeemFunc}
                isRedeeming={isRedeeming}
              />
            </div>
            {points?.expiringPoints || points?.expiringPoints !== undefined ? (
              <div className='points-expiry'>
                <h2 className='sz-16'>{t.expiryPoints.title}</h2>
                <p className='points-expiry-number sz-16'>
                  <b>{t.expiryPoints.points(points.expiringPoints)}</b>{' '}
                  {t.expiryPoints.pointsTime(points.pointsExpiryDays)}
                </p>
                <p className='points-expiry-time expiry-time-color sz-12'>
                  {t.expiryPoints.expiryTime}
                  <a
                    target='_blank'
                    rel='noreferrer'
                    className='expiry-time-color'
                    href={
                      currency.nameEn === 'GBP'
                        ? 'https://floward.co.uk/Terms'
                        : 'https://floward.com/en-kw/Terms'
                    }
                  >
                    {t.expiryPoints.terms}
                  </a>
                </p>
              </div>
            ) : null}

            <section className='points-history-section'>
              <PointsHistory />
            </section>

            <div className='points-section'>
              {/* <VipPoints points={points} isVip={points.isVIP}></VipPoints> */}
              <EarnPoints />
            </div>
          </div>
          {isCongratsModelOpen ? (
            <Congrats toggleFunc={closeModal} points={points} currency={currency} />
          ) : null}
        </>
      )}
    </div>
  );
}

export default Home;
