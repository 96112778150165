export const en = {
  flowardPoints: 'Floward Points',
  flowardVip: 'Floward VIP',
  home: {
    desc: 'Collect Floward points every time you place an order with us. The more gifts you give, the more points you get.',
    redeemBtn: 'Redeem Points',
    noPointRedeemBtn: 'No Points to Redeem',
    missingPointsRedeemBtn: amount => `${amount} More Points to Redeem`,
  },
  balance: {
    title: 'Available balance',
    points: 'Points',
    desc: 'Credit will be automatically added to your Floward wallet account when you redeem your points',
  },
  earnpoints: {
    title: 'How can I earn more points?',
    link: 'Learn More',
    points: {
      title: (amount, currency) => `Earn ${amount} points for every ${currency} 1  spent`,
      desc: (amount, currency) =>
        `For every ${amount} points you’ll get ${currency} 1  Floward credit`,
    },
    delivery: {
      title: 'You can use your points to get Floward credit in any country',
      desc: 'Points will appear in your account after the order is delivered',
    },
    vat: {
      title: 'Points are calculated based on your spend minus any discounts and VAT',
    },
    note: {
      title: 'Please note the value of points may change in the future',
      desc: 'All new points earned from June 1 2022 will expire within 60 days',
      note: 'If you cancel or refund an order your points will be void',
    },
  },
  pointsHistory: {
    title: 'Points History',
    viewDetails: 'View Details',
    desc: 'Go through your points history',
    expiredBalancePeriod: '1 year period',
    pts: 'Pts',
    orderId: 'order ID',
    Purchase: 'Purchase',
    Redeem: 'Redeemed Points',
    ExpiredBalance: 'Expired',
    Signup: 'Welcome Points',
    transactionEmptyTitle: `You haven't earned any points yet!`,
    transactionEmptySub: `To get involved in our loyalty program, place an order and points will be added to your account.`,
  },
  congrats: {
    title: 'CONGRATULATIONS!',
    desc: (points, currency) =>
      `A balance of ${points} ${currency} has been added to your floward wallet `,
    note: 'You can use the credit with next order',
    errorPoints: "Sorry! You don't have enough points.",
  },
  expiryPoints: {
    title: 'Points Expiry',
    points: points => `${points} points`,
    pointsTime: expiringDays => `expiring in next ${expiringDays} days`,
    expiryTime: `All points earned from June 1 2022 will be expiring within 60 days as part of our updated loyalty program.`,
    terms: 'Please find T&C here',
  },
  vip: {
    yourVip: `You're a VIP member`,
    desc: 'Get access to special deals and offers with each order you place through our application.',
    pointsTitle: 'Your total collected points',
    reachVip: points => `Earn only ${points} points to unlock `,
    calculatingPoints: `We're calculating points earned within last 6 months`,
    vipReached: (points, date) => `Earn ${points} points before ${date} to remain as VIP `,
    remainVip: 'You earned enough points to maintain your VIP status',
    benefits: {
      title: 'What are the benefits of Floward VIP?',
      desc: 'Enjoy a VIP gifting experience, you’ll get exclusive deals, lower prices, and unique offers.\nMore benefits are coming soon!',
      whyPointsChangeTitle: 'Why is my points balance changing?',
      whyPointsChangeDesc:
        'We calculate real-time points accumulated within the last 6 months and update the balance accordingly. The amount changes as there could be new points added to your account recently or the points are older than 6 months.',
      vipStatus:
        'Your VIP status will be valid for 6 months, and it will be automatically renewed if you have earned the required points within the validity period.',
      firstBenefit: 'Free delivery',
      secondBenefit: 'Priority delivery',
      commingSoon: 'More benefits coming soon..',
      earnInfo: remainingPoints =>
        `Earn ${remainingPoints} points within 6 months to become a Floward VIP member. Your VIP status will be valid for 6 months, and it will be automatically renewed if you have earned the required points within that period.`,
    },
    yourStatus: 'Your status',
    yourTotalPoints: 'Your total collected points',
    pointsToUnlock: 'Points to unlock VIP',
  },
};
