export const getUserId = ({ state }) => state.PointsReducer.userId;

export const getJWTData = ({ state }) => state.PointsReducer.jwt;

export const getPointsData = ({ state }) => state.PointsReducer.points;

export const redeemedPointsData = ({ state }) => state.PointsReducer.redeemedPoints;

export const getCurrencyData = ({ state }) => state.PointsReducer.currency;

export const getTransactions = ({ state }) => state.PointsReducer.transactions;
