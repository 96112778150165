import { combineReducers } from 'redux';
//reducers
import LangReducer from './reducers/LangReducer';
import PointsReducer from './reducers/PointsReducer';
import AppReducer from './reducers/AppReducer';

const rootReducer = combineReducers({
    LangReducer,
    PointsReducer,
    AppReducer
});

export default rootReducer;