import { SET_LOADING, SET_REDEEMING } from '../types/AppTypes';
//constants
import { updateObject } from '../../constants/Helpers';

const initialState = {
	loading: true,
    isRedeem: false
};

const AppReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING: {
            return updateObject(state, { loading: action.load });
        }
        case SET_REDEEMING: {
            return updateObject(state, { isRedeem: action.redeem });
        }
		default:
			return state;
	}
};

export default AppReducer;