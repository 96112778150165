import React from 'react';
import { useSelector } from 'react-redux';
import { getLang, getLangJson } from '../../store/selectors/LangSelector';
import { ReactComponent as BenefitLogo } from "../../assets/images/floward-vip-lg-en.svg";
import { ReactComponent as BenefitLogoAr } from "../../assets/images/floward-vip-lg-ar.svg";
import { ReactComponent as ListStar } from "../../assets/images/list-star.svg";
function VipBenefits() {
    const lang = useSelector((state) => getLang({ state })),
        t = useSelector((state) => getLangJson({ state }));

    return (
        <div className="vip-benefits-div">
            <section className='qa-section'>
                <div>
                <h2 className='title sz-16'>{t.vip.benefits.title}</h2>
                <p className='description sz-12'>{t.vip.benefits.desc}</p>
                </div>
                <div>
                <h2 className='title sz-16'>{t.vip.benefits.whyPointsChangeTitle}</h2>
                <p className='description sz-12'>{t.vip.benefits.whyPointsChangeDesc}</p>
                </div>
            </section>
            <div className="benefit-list vip-blue">
                {lang === "ar" ? <BenefitLogoAr /> : <BenefitLogo />}
                <p className="benefit-list-title sz-12"> {t.vip.benefits.earnInfo(6000)} </p>
                <ul className="benefit-list-advantage">
                    <li className="list-item sz-12"><ListStar className="star" />{t.vip.benefits.firstBenefit}</li>
                    <li className="list-item sz-12"><ListStar className="star" />{t.vip.benefits.secondBenefit}</li>
                    <li className="list-item sz-12"><ListStar className="star" />{t.vip.benefits.commingSoon}</li>
                </ul>
            </div>
            <p className="description sz-12">{t.vip.benefits.vipStatus}</p>

        </div>
    );

}
export default VipBenefits;