import { INIT_SESSION, GET_TOKEN, GET_POINTS, REDEEM_POINTS, SET_POINTS, GET_CURRENCY, SET_CURRENCY, GET_TRANSACTIONS } from '../types/PointsTypes';
//services
import PointsService from '../../services/PointsService';
//toast
import { toast } from 'react-toastify';
//actions
import { setLoading, setRedeeming } from './AppActions';
import { setLang } from './LangActions';
//selectors
import { getJWTData } from '../selectors/PointsSelector';
//constants
import { decodeToken } from '../../constants/Helpers';

export const setToken = (token) => async (dispatch) => {
    try {
        dispatch({
            type: INIT_SESSION,
            userId: token,
        });
    } catch (err) {
        // is Empty
    }
};

export const getJWT = (token) => async (dispatch) => {
    try {
        const res = await PointsService.getToken(token);
        dispatch({
            type: GET_TOKEN,
            jwt: res.data.jwt,
        });
        const lang = res.data.jwt ? decodeToken(res.data.jwt).locale.indexOf("en") : 1;
        if(lang > -1){
            dispatch(setLang("en"));
        }else{
            dispatch(setLang("ar"));
        }
    } catch (err) {
        dispatch(setLoading(false));
        toast.error(err.response);
    }
};

export const getCurrency = (currency) => async (dispatch) => {
    try {
        const res = await PointsService.getCurrency(currency);
        dispatch({
            type: GET_CURRENCY,
            currency: res.data,
        });
    } catch (err) {
        dispatch(setLoading(false));
        toast.error(err.response);
    }
};

export const setCurrency = (currency) => async (dispatch) => {
    try {
        dispatch({
            type: SET_CURRENCY,
            currency: currency,
        });
    } catch (err) {
        dispatch(setLoading(false));
        toast.error(err.response);
    }
};

export const getPoints = (currency) => async (dispatch, getState) => {
    try {
        const state = getState(),
            jwt = getJWTData({ state }),
            authorization = `Bearer ${jwt}`,
            params = {
                currency: currency
            }, 
            res = await PointsService.getPoints(authorization, params);

        dispatch({
            type: GET_POINTS,
            points: res.data,
        });
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        toast.error(err.response);
    }
};

export const setPoints = (data) => ({
    type: SET_POINTS,
    points: data,
});

export const redeemPoints = (data) => async (dispatch, getState) => {
    dispatch(setRedeeming(true));
    try {
        const state = getState(),
            jwt = getJWTData({ state }),
            authorization = `Bearer ${jwt}`,
            res = await PointsService.redeemPoints(authorization, data);
        dispatch({
            type: REDEEM_POINTS,
            redeemedPoints: res.data,
        });
        dispatch(setRedeeming(false));
    } catch (err) {
        dispatch(setRedeeming(false));
        toast.error(err.response);
    }
};

export const getStatements = () => async (dispatch, getState) => {
    try {
        const state = getState(),
        jwt = getJWTData({ state }),
        authorization = `Bearer ${jwt}`;

        const res = await PointsService.getStatements(authorization);
        
        dispatch({
            type: GET_TRANSACTIONS,
            transactions: res.data,
        });
    } catch (err) {
        toast.error(err.response);
    }
};