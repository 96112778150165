import React from 'react';
import {ReactComponent as EarnPointsIcon} from '../../assets/images/earnPointsIcon.svg';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
//selectors
import { getLangJson, getLang } from '../../store/selectors/LangSelector';

function Congrats({toggleFunc, points, currency}) {
    const t = useSelector((state) => getLangJson({ state })),
        lang = useSelector((state) => getLang({ state }));
    return(
        <div className="overlay" onClick={() => toggleFunc()}>
            <div className="congrats-div white-bg">
                <div className="congrats-content">
                    <p className="congrats-title sz-20 medium-txt">{t.congrats.title}</p>
                    <p className="congrats-txt sz-14 medium-txt">{t.congrats.desc(points.redeemableBalance,currency? lang === "ar"? currency?.nameAr : currency?.nameEn : "")}</p>
                    <p className="congrats-note sz-14 light-txt">{t.congrats.note}</p>
                    <EarnPointsIcon className="congrats-icon"/>
                </div>
            </div>
        </div>
    );
}

Congrats.propTypes = {
    toggleFunc: PropTypes.func.isRequired,
    points: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired
};

export default Congrats;