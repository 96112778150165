export const updateObject = (oldObject, UpdatedValues) => {
	return {
		...oldObject,
		...UpdatedValues,
	};
};

export const decodeToken = (token) => {
	return JSON.parse(atob(token.split('.')[1]));
};

export const getQueryParams = (params) => {
	const queryParams = new URLSearchParams(params),
		paramsObj = {};

	for (let [key, value] of queryParams.entries()) {
		paramsObj[key] = value;
	}
	return paramsObj;
};