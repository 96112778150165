import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../components/common/Loader';
import VipPoints from '../components/vip/VipPoints';
//svg
import { ReactComponent as VipLogo } from '../assets/images/vip-logo.svg';
import { ReactComponent as ArabicVipLogo } from '../assets/images/vip-logo-arabic.svg';
//selectors
import { getLoading } from '../store/selectors/AppSelector';
import { getLangJson, getLang } from '../store/selectors/LangSelector';
import { getPointsData, getCurrencyData } from '../store/selectors/PointsSelector';
import VipBenefits from '../components/vip/VipBenefits';

function Vip() {

  const loading = useSelector((state) => getLoading({ state })),
    t = useSelector((state) => getLangJson({ state })),
    lang = useSelector((state) => getLang({ state })),
    points = useSelector((state) => getPointsData({ state })),
    currency = useSelector((state) => getCurrencyData({ state }));


  return (
    <div className="main-container">
      {loading ?
        <Loader height={"100%"} />
        :
        <>
          <div className="blue-dark-section vip-blue blue-section">
            {lang === 'ar' ? <ArabicVipLogo className="home-title" /> : <VipLogo className="home-title" />}
            <p className="home-txt sz-16">
              {t.vip.desc}
            </p>
          </div>
          <div className="point">
            <div className="white-section-content">
              <VipPoints points={points} currency={currency} isVip={points.isVIP} />
            </div>
            <div className="white-section-content">
              <VipBenefits remainingPoints={points.remainingPoints} />
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default Vip;