//services
import { apiService } from './HttpService';
//end points
import { initSessionEndPoint, getTokenEndPoint, getPointsEndPoint, redeemPointsEndPoint, getCurrencyEndPoint, getStatementsEndPoint } from './EndPoints';

class PointsService {
	static initSession(data) {
		return apiService({
			method: 'POST',
			url: initSessionEndPoint(),
			data
		});
	}
	static getToken(data) {
		return apiService({
			method: 'GET',
			url: getTokenEndPoint(data),
		});
	}
	static getPoints(authorization, params) {
		return apiService({
			method: 'GET',
			url: getPointsEndPoint(),
            headers: { 
                'Authorization': authorization
            },
            params
		});
	}
	static redeemPoints(authorization, data) {
		return apiService({
			method: 'PUT',
			url: redeemPointsEndPoint(),
            headers: { 
                'Authorization': authorization
            },
            data
		});
	}
	static getCurrency(data) {
		return apiService({
			method: 'GET',
			url: getCurrencyEndPoint(data),
		});
	}
	static getStatements(authorization) {
		return apiService({
			method: 'GET',
			url: getStatementsEndPoint(),
			headers: {
				'Authorization': authorization
			}
		});
	}
}

export default PointsService;
