import React from 'react';
import Loader from './Loader';
import PropTypes from 'prop-types';

function CustomButton({disabled, text, clickFunc, isRedeeming}) {
    return(
        <button className={`loyalty-btn medium-txt sz-16 ${isRedeeming? "loading-btn" : ""} ${disabled? "disabled-btn" : ""}`} disabled={disabled} onClick={() => clickFunc()}>
            {isRedeeming? <Loader height={"19px"} /> : text}
        </button>
    );
}

CustomButton.propTypes = {
    text: PropTypes.string.isRequired,
    clickFunc: PropTypes.func.isRequired,
    isRedeeming: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired
};

export default CustomButton;