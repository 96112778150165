import { SET_LOADING, SET_REDEEMING } from '../types/AppTypes';

export const setLoading = (load) => ({
	type: SET_LOADING,
	load,
});

export const setRedeeming = (redeem) => ({
	type: SET_REDEEMING,
	redeem,
});